
import Vue from "vue";
import { Survey } from "@/models/survey";
import axios from "@/plugins/axios";
import i18n from "@/i18n";

export default Vue.extend({
  name: "AGSReports",
  data: () => ({
    surveys: [] as Survey[],
  }),
  computed: {
    selectedSurvey: {
      get() {
        return this.$store.getters["agsreport/getYearSelected"];
      },
      set(year) {
        return this.$store.dispatch("agsreport/changeYearSelected", year);
      },
    },
    reportList() {
      return [
        {
          name: i18n.t("reports.agsreports.demographic"),
          nested: false,
          to: { name: "Demographic" },
        },
        {
          name: i18n.t("reports.agsreports.identifiedpatients"),
          nested: true,
          children: [
            {
              name: i18n.t("reports.agsreports.latestdata"),
              to: { name: "Patients" },
            },
            {
              name: `${i18n.t("reports.agsreports.latestdata")} ${i18n.t(
                "reports.agsreports.gni"
              )}`,
              to: { name: "PatientsGNI" },
            },
          ],
        },
        // {
        //     name: i18n.t("reports.agsreports.datasource"),
        //     nested: false,
        // },
        {
          name: i18n.t("reports.agsreports.factorusage"),
          nested: false,
          to: { name: "FactorUsage" },
        },
        // {
        //     name: i18n.t("reports.agsreports.countriesdata"),
        //     nested: false,
        // },
        {
          name: i18n.t("reports.agsreports.populationstatics"),
          nested: false,
          to: { name: "PopulationStatistics" },
        },
        {
          name: i18n.t("reports.agsreports.bleedingdisorders"),
          nested: false,
          to: { name: "BleedingDisorders" },
        },
        {
          name: i18n.t("reports.agsreports.genderdistribution"),
          nested: true,
          children: [
            {
              name: i18n.t("reports.agsreports.general"),
              to: { name: "GenderDistribution" },
            },
            {
              name: i18n.t("reports.agsreports.latestdata"),
              to: { name: "GenderDistributionLatest" },
            },
          ],
        },
        {
            name: i18n.t("reports.agsreports.inhibitors"),
            nested: false,
            to: { name: 'Inhibitors' }
        },
        {
            name: i18n.t("reports.agsreports.age"),
            nested: true,
            children: [
              {
                name: `${i18n.t("reports.agsreports.hemophilia")} A`, to: { name: "AgeHemophiliaA" }
              },
              {
                name: `${i18n.t("reports.agsreports.hemophilia")} B`, to: { name: "AgeHemophiliaB" }
              },
              {
                name: `${i18n.t("reports.agsreports.hemophilia")} ${i18n.t("reports.agsreports.typeunknown")}`, 
                to: { name: "AgeHemophiliaUnknown" }
              },
              {
                name: "vWD", to: { name: "AgeVWD" }
              },
              {
                name: `${i18n.t("reports.agsreports.hemophilia")} A - ${i18n.t("reports.agsreports.latestdata")}`,
                to: { name: "AgeHemophiliaALatest" }
              },
              {
                name: `${i18n.t("reports.agsreports.hemophilia")} B - ${i18n.t("reports.agsreports.latestdata")}`,
                to: { name: "AgeHemophiliaBLatest" }
              },
              {
                name: `${i18n.t("reports.agsreports.hemophilia")} ${i18n.t("reports.agsreports.typeunknown")} - ${i18n.t("reports.agsreports.latestdata")}`,
                to: { name: "AgeHemophiliaULatest" }
              },
              {
                name: `vWD - ${i18n.t("reports.agsreports.latestdata")}`, to: { name: "AgeVWDLatest" }
              },
            ]
        },
        {
          name: i18n.t("reports.agsreports.HivHcvInfection.title"),
          nested: false,
          to: {name: "HivHcvInfection"}
        },
        {
          name: i18n.t("reports.agsreports.severityHemophiliaByGenderAndGNI.title"),
          nested: false,
          to: {name: "severityHemophiliaByGenderAndGNI"}
        },
        {
          name: i18n.t("reports.agsreports.PercentagePatientsProphylaxis.title"),
          nested: false,
          to: { name: 'percentagePatientsProphylaxis' }
        },
        {
          name: i18n.t("reports.agsreports.FVIIIperCountry.title"),
          nested: false,
          to: { name: 'FVIIIperCountry' }
        },
        {
          name: i18n.t("reports.agsreports.FIXperCountry.title"),
          nested: false,
          to: { name: 'FIXperCountry' }
        },
        {
          name: i18n.t("reports.agsreports.FactorVIIIUsage.title"),
          nested: false,
          to: { name: 'FactorVIIIusage' }
        },
        {
          name: i18n.t("reports.agsreports.FactorIXUsage.title"),
          nested: false,
          to: { name: 'FactorIXUsage' }
        },
        {
          name: i18n.t("reports.agsreports.FVIIIUsagebyGNI.title"),
          nested: false,
          to: { name: 'FVIIIUsagebyGNI' }
        },
        {
          name: i18n.t("reports.agsreports.FIXUsagebyGNI.title"),
          nested: false,
          to: { name: 'FIXUsagebyGNI' }
        },
        {
          name: i18n.t("reports.agsreports.FVIIIUsagebyGNIAndPatiens.title"),
          nested: false,
          to: { name: 'FVIIIUsagebyGNIAndPatiens' }
        },
        {
          name: i18n.t("reports.agsreports.FIXUsagebyGNIAndPatiens.title"),
          nested: false,
          to: { name: 'FIXUsagebyGNIAndPatiens' }
        },
        {
          name: i18n.t("reports.agsreports.otherBleedingDisordersLatestData.title"),
          nested: false,
          to: { name: 'OtherBleedingDisordersLatestData' }
        },
        {
          name: i18n.t("reports.agsreports.TreatmentByGNIOverTime.title"),
          nested: false,
          to: { name: 'TreatmentByGNIOverTime' }
        }
        // {
        //     name: `HIV & HCV ${i18n.t("reports.agsreports.infection")}`,
        //     nested: false,
        // },
        // {
        //     name: i18n.t("reports.agsreports.severityhemophilia"),
        //     nested: false,
        // },
        // {
        //     name: i18n.t("reports.agsreports.percentageprophylaxis"),
        //     nested: false,
        // },
        // {
        //     name: "FVIII",
        //     nested: true,
        //     children: [
        //       {
        //         name: i18n.t("reports.agsreports.percountry")
        //       },
        //       {
        //         name: i18n.t("reports.agsreports.usage")
        //       },
        //       {
        //         name: `${i18n.t("reports.agsreports.usage")} ${i18n.t("reports.agsreports.gni")}`
        //       },
        //       {
        //         name: `${i18n.t("reports.agsreports.usage")} ${i18n.t("reports.agsreports.gni")} ${i18n.t("reports.agsreports.perpatient")}`
        //       },
        //       {
        //         name: i18n.t("reports.agsreports.percapita")
        //       },
        //     ]
        // },
        // {
        //     name: "FVIX",
        //     nested: true,
        //     children: [
        //       {
        //         name: i18n.t("reports.agsreports.percountry")
        //       },
        //       {
        //         name: i18n.t("reports.agsreports.usage")
        //       },
        //       {
        //         name: `${i18n.t("reports.agsreports.usage")} ${i18n.t("reports.agsreports.gni")}`,
        //       },
        //     ]
        // },
        // {
        //     name: i18n.t("reports.agsreports.careinhibitors"),
        //     nested: false,
        // },
        // {
        //     name: i18n.t("reports.agsreports.otherbleedingdisorders"),
        //     nested: false,
        // },
      ]
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  created() {
    if (this.$route.name !== "AGSReports") {
      this.$router.push({ name: "AGSReports" });
    }
    axios
      .get(`/survey/all/?beforeCurrentDate=${false}`)
      .then((res) => {
        this.surveys = res.data;
        if (this.surveys.length > 0 && this.selectedSurvey.id == undefined)
          this.selectedSurvey = this.surveys[0];
      })
      .catch((e) => console.log(e));
  },
  watch: {},
});
